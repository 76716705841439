import Gallery from "../Libs/Gallery/Gallery/Gallery";
import celtic_dress from './images/celtic_dress.jpg';
import celtic_dress_2 from './images/celtic_dress_2.jpg';
import medieval_dress from './images/medieval_dress.jpg';
import medieval_dress_2 from './images/medieval_dress_2.jpg';

export default function Costumes() {
    return (
        <>
            <h3>Keltské šaty</h3>
            <Gallery
                images={[
                    {
                        src: celtic_dress,
                        alt: 'celtic',
                        className: 'fullWidth',
                    },
                    {
                        src: celtic_dress_2,
                        alt: 'celtic',
                        className: 'fullWidth',
                    },
                ]}
            />

            <h3>Středověké šaty</h3>
            <Gallery
                images={[
                    {
                        src: medieval_dress,
                        alt: 'celtic',
                        className: 'fullWidth',
                    },
                    {
                        src: medieval_dress_2,
                        alt: 'celtic',
                        className: 'fullWidth',
                    },
                ]}
            />

        </>
    );
}
