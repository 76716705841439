import './App.css';
import Menu from "./Components/Menu/Menu";
import Content from "./Components/Content/Content";

function App(props) {
    let url = window.location.pathname.substring(1);

    return (
        <div className="App">
            <Menu
                url={url}
            />
            <Content
                url={url}
            />
        </div>
    );
}

export default App;
