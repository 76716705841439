import {useEffect, useState} from "react";
import Home from "../Tabs/Home/Home";
import Programme from "../Tabs/Programme/Programme";
import Register from "../Tabs/Register/Register";
import Technical from "../Tabs/Technical/Technical";
import './Content.css';

export default function Content({url}) {
    const [content, setContent] = useState(null);

    useEffect(() => {
        switch(url) {
            case 'programme':
                setContent(<Programme />);
                break;
            case 'register':
                setContent(<Register />);
                break;
            case 'technical':
                setContent(<Technical />);
                break;
            default:
                setContent(<Home />);
                break;
        }
    }, [url]);

    return (
        <div className='contentBlock'>
            {content}
        </div>
    );
}
