export default function MenuElement({url, className, onClick, children, isActive}) {
    let click = onClick;

    if (url !== null && url !== undefined) {
        click = () => {
            if(onClick) {
                onClick();
            }
            window.location.href = '/' + url?.trimStart('/');
        };
    }

    let classNames = ['element'];
    if (isActive) {
        classNames.push('active');
    }
    if (className) {
        classNames.push(className);
    }


    return (
        <div onClick={click} className={classNames.join(' ')}>
            {children}
        </div>
    );
}
