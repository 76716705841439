import './Gallery.css';
import Image from "../Image/Image";
import {useState} from "react";

export default function Gallery({images, className}) {
    const [img, setImg] = useState(null);

    return (
        <>
            <div className={'gallery ' + (className ? className : '')}>
                {images.map((image, index) => {
                    return <Image
                        key={index}
                        src={image.src}
                        alt={image.alt}
                        className={image.className ?? ''}
                        onClick={(src) => setImg(index)}
                    />
                })}
            </div>
            {img !== null && <div className={'imagePreview'} onClick={() => setImg(null)}>
                <img src={images[img].src} alt={images[img].alt}/>
                {images[img].description && (
                    <div className='description'>{images[img].description}</div>
                )}
            </div>}
        </>
    );
}
