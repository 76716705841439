import './GridTile.css';

export default function GridTile({children, className, style, title}) {
    return (
        <div className={`gridTile ${className}`} style={style}>
            <div className='content'>
                <div className='title'>
                    {title}
                </div>
                {children}
            </div>
        </div>
    );
}
