import './Programme.css';
import Timeline from "../../Timeline/Timeline";
import Timepoint from "../../Timeline/Timepoint";

export default function Programme() {
    return (
        <div className='content'>
            <h1>Program</h1>
            <br/>
            <Timeline>
                <Timepoint
                    date='pátek 16:00'
                >
                    Sjíždí se dobrovolníci na chystání areálu
                </Timepoint>
                <Timepoint
                    date='sobota 08:00'
                >
                    Budíček pomocníků
                </Timepoint>
                <Timepoint
                    date='09:00'
                >
                    První možnost příjezdu
                </Timepoint>
                <Timepoint
                    date='10:45'
                >
                    Poslední možnost příjezdu včas
                </Timepoint>
                <Timepoint
                    date='11:00'
                >
                    <h3>Obřad</h3>
                </Timepoint>
                <Timepoint
                    date='11:30'
                >
                    Společné focení
                </Timepoint>
                <Timepoint
                    date='12:00'
                >
                    <h3>Jídlo</h3>
                </Timepoint>
                <Timepoint
                    date='14:00 - 15:00'
                >
                    <h3>Program I</h3>
                </Timepoint>
                <Timepoint
                    date='15:30'
                >
                    <h3>Dort</h3>
                </Timepoint>
                <Timepoint
                    date='16:00 - 18:00'
                >
                    <h3>Program II + III</h3>
                </Timepoint>
                <Timepoint
                    date='17:00'
                >
                    <h3>Další jídlo</h3>
                </Timepoint>
                <Timepoint
                    date='18:00'
                >
                    <h3>Program IV</h3>
                </Timepoint>
                <Timepoint
                    date='19:00'
                >
                    <h3>Program V</h3>
                </Timepoint>
                <Timepoint
                    date='20:00'
                >
                    <h3>Táborák</h3>
                </Timepoint>
                <Timepoint
                    date='Neděle 09:00'
                >
                    Budíček spáčů ve skanzenu
                </Timepoint>
                <Timepoint
                    date='Neděle 09:30'
                >
                    Společné dojídání zbytků k snídani
                </Timepoint>
                <Timepoint
                    date='Neděle 10:00'
                >
                    Vyklizení skanzenu -> otvírá se návštěvníkům
                </Timepoint>
            </Timeline>
            <div></div>
        </div>
    );
}
