import {useState} from "react";
import './Menu.css';
import MenuElement from "./MenuElement";

export default function Menu({url}) {
    // create vatiable className
    const [menu, setMenu] = useState('menu');

    const expandMenu = () => {
        if (menu === 'menu') {
            setMenu('menu responsive');
        } else {
            setMenu('menu');
        }
    };

    return (
        <>
            <div className='nav'>
                <div className={menu} id="menu">
                    <div className='home'>
                        <MenuElement
                            url={''}
                            isActive={url === ''}
                            className={'img'}
                        >
                            <div className={'knot'} />
                        </MenuElement>
                        <MenuElement
                            isActive={false}
                            className='expandMenu'
                            onClick={expandMenu}
                        >
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </MenuElement>
                    </div>
                    <div className='other'>
                    <MenuElement
                            url={'programme'}
                            isActive={url === 'programme'}
                        >
                            Program
                        </MenuElement>
                        <MenuElement
                            url={'technical'}
                            isActive={url === 'technical'}
                        >
                            Praktické informace
                        </MenuElement>
                        <MenuElement
                            url={'register'}
                            isActive={url === 'register'}
                        >
                            Registrace
                        </MenuElement>
                    </div>
                </div>
            </div>
        </>
    );
}
