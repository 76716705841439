import './Timeline.css';

export default function Timeline({children}) {
    return (
        <div id="content">
            <ul className="timeline">
                {children}
            </ul>
        </div>
    );
}
