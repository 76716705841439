import './Register.css';

export default function Register() {
    return (
        <div className='content' style={{height: '100%'}}>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSebRpt7_GYhbavFIibfZ8u8ZAcXMZCdgEcJWaOEhXdX6zH5AQ/viewform?embedded=true"
                className='googleForm'
                title={'Register form'}
            >
                Načítání…
            </iframe>
        </div>
    );
}
