import './Technical.css';
import GridWrapper from "../../Libs/Grid/GridWrapper/GridWrapper";
import GridTile from "../../Libs/Grid/GridTile/GridTile";
import nasavrky from './images/nasavrky.png';
import mapa from "./images/Mapa.png"
import Gallery from "../../Libs/Gallery/Gallery/Gallery";
import Costumes from "../../Costumes/Costumes";

export default function Technical() {
    return (
        <div className='technical content'>
            <h1>Praktické informace</h1>

            <GridWrapper>
                <GridTile
                    title={'Dress Code'}
                >
                    <div style={{fontWeight: '700', fontSize: '16px', textAlign: 'center'}}>(Aneb co na sebe)</div>
                    <br/>
                    Svatba bude neformální, takže si vezměte cokoli, co Vám bude pohodlné.
                    Je dobré vzít v úvahu, že obřad, jídlo i přídavné aktivity budou probíhat ve skanzenu
                    (tzn. na trávě, takže lodičky pravděpodobně nevyužijete). <br/>
                    Pokud nám chcete udělat radost a obléct se nějak tematicky,
                    tak zkuste něco nejlépe v tomto pořadí: <br/>
                    <ul className='list'>
                        <li>keltské</li>
                        <li>středověké, vikingské, kilt, kroj</li>
                        <li>jinak tematické</li>
                        <li>nebo jenom něco, co se Vám líbí</li>
                    </ul>
                    <Costumes />
                </GridTile>
                <GridTile
                    title={'Doprava'}
                >
                    Až k samotnému skanzenu se dá pohodlně dojet autem,
                    parkoviště by mělo být dostatečně velké pro všechny.
                    Časem pravděpodobně přibude seznam řidičů s volnými místy v autě
                    ochotných přibrat další spolucestující.
                    <br/>
                    <br/>
                    Na místo se dá zároveň dostat autobusem, ale je to celkem dlouhá cesta a autobusů moc nejezdí.
                </GridTile>
                <GridTile>
                    <img src={nasavrky} alt="Nasavrky" className='fullWidth'/>
                    <div style={{textAlign: 'center'}}>Jak to v Nasavrkách ve skanzenu vypadá</div>
                </GridTile>
                <GridTile
                    title={'Spaní'}
                >

                    Na výběr je několik možností, kde ze soboty na neděli (případně i z pátka na sobotu) přespat:

                    <h3>Skanzen</h3>
                    Přímo ve skanzenu je keltské stavení, ve kterém se bude dát přenocovat.
                    Jedná se o dřevenou stavbu, kde se bude spát na zemi na vlastních karimatkách a
                    spacácích (popřípadě čemkoli jiném, co uznáte za vhodné). Zároveň se dá postavit hned vedle domku stan,
                    případně přespat pod širákem.

                    <h3>Kemp</h3>
                    Před branou do skanzenu vedle parkoviště je pěkný travnatý plácek, kde se dá postavit stan,
                    pokud vám nevyhovuje hromadné spaní v areálu a chcete mít svůj klid.

                    <h3>Hospoda</h3>
                    V hospodě před branou do skanzenu v patře nad výčepem je velké letiště s několika matracemi, kde
                    se dá přespat trochu pohodlněji než v samotném skanzenu.

                    <h3>Jiné alternativy</h3>
                    Pokud se vám nelíbí ani jedna z výše uvedených variant, dá se zařídit ubytování v samotných
                    Nasavrkách. Máme zamluvených několik apartmánu (konkrétně
                    <a href={"https://www.apartmanypodcapy.cz/"}> Apartmány Pod Čápy </a> a
                        <a href={"https://www.nasavrky.cz/penzion-galerka-ochoz/os-1150"}> Penzion Galerka Ochozka</a>)
                    pro všechny, kteří nám dali vědět (a možná i něco navíc).
                </GridTile>
                <GridTile
                    title={'Dary'}
                >
                    <b>Dary nechceme</b>, ale můžete pomoct, nebo přispět. <br/>
                    Jelikož už spolu dostatečně dlouho žijeme, rekonstrukci máme za sebou a byt máme vybavený, tak jsou
                    pro nás
                    dary celkem zbytečné. <br/>
                    Pokud nás ale přecejen chcete nějak podpořit nebo nám nějak pomoci, můžete buď:
                    <ul className='list'>
                        <li>Pomoct s organizací svatby a programu. V tom případě nás neváhejte kontaktovat (stačí
                            zaškrtnout políčko v registračním formuláři) a rádi pro Vás
                            najdeme
                            nějakou zábavu. <br/>
                            Můžete dělat program, péct nějaké dobroty nebo se podílet i jakkoli jinak.
                        </li>
                        <li>Přispět na svatbu buď na místě, nebo na účet: 2801870718/2010</li>
                    </ul>
                </GridTile>
                <GridTile
                    title={'Doporučená výbava'}
                >
                    <ul className='list'>
                        <li>teplé oblečení na večer - budeme sedět venku, ale pravděpodobně rozděláme oheň</li>
                        <li>recyklovatelné kelímky na pivo; ideálně několik, protože ostatní kamarádi na ně určitě
                            zapomenou
                        </li>
                        <li>čelovka - areál není moc osvětlený a dostat se ve dvě ráno na záchod může být docela
                            bojovka
                        </li>
                        <li>pevnější boty (ideálně ne lodičky) - celý den budete chodit po trávě a hlíně</li>
                        <li>roh na medovinu, pokud vlastníte</li>
                        <li>spacák a karimatka, pokud chcete spát ve skanzenu/stanu</li>
                        <li>kytaru nebo jiný hudební nástroj, na který si rádi u ohně zahrajete</li>
                    </ul>
                </GridTile>
                <GridTile title={'Mapa areálu'}>
                    <Gallery
                        images={[
                            {
                                src: mapa,
                                alt: 'Mapa',
                                className: 'fullWidth',
                                description: mapDescription(),
                            },
                        ]}
                        className={'fullWidth'}
                    />
                    <div style={{textAlign: 'center'}}></div>
                </GridTile>
                <GridTile title={'Zpěvník'}>
                    V programu je i večerní zpívání u ohně, a proto je vítán každý hudební nástroj.<br />
                    A abychom nemuseli vymýšlet na místě, co budeme zpívat, tak jsme připravili i zpěvník s magickým počtem písniček:<br />
                    <h3>Elektronická verze na google doc:</h3>
                    <a target='_blank' rel='noreferrer' href='https://docs.google.com/document/d/169cUi0EzePHjtrWWIF73YM8UkAT-TbxMSnGjqH9tbA8/edit?usp=sharing'>https://docs.google.com/document/d/...</a>. Zde jsou písničky seřazeny abecedně a hodí se pro čtení z mobilu / tabletu<br />
                    <h3>Verze pro tisk:</h3>
                    Pro tisk na tiskárně, která umí tisknout oboustranně a na šířku papíru použijte tento odkaz:<br />
                    <a target='_blank' rel='noreferrer' href='https://drive.google.com/file/d/1dHaYvIxgwqrmpJD106ZuqGBzONNkYDw6/view?usp=sharing'>Tisk - na šířku</a>.<br /><br />
                    Pro tisk na tiskárně, která umí tisknout oboustranně, ale nezvládá tisk na šířku (jako naše) je zde upravená verze pro tisk, aby nebyly převrácené strany:<br />
                    <a target='_blank' rel='noreferrer' href='https://drive.google.com/file/d/1nhOSGPe_0LaHtOpxAbUQseDe9nHtWSCI/view?usp=sharing'>Tisk - na výšku</a>.<br /><br />

                    ... a co se zpěvníkem po tisku? <br />
                    Stačí listy správně seřadit na sebe a celé to přehnout na půlku. Vyjde Vám tak kompaktní a abecedně srovnaná knížečka. Poté si ještě můžete střed prošít libovolnou nití nebo provázkem a máte hotovo.
                </GridTile>
            </GridWrapper>
        </div>
    );
}


const mapDescription = () => {
    return (
        <>
            Mapka areálu
        </>
    );
}
