import "./Home.css";
import HomeHeader from "./HomeHeader";

export default function Home() {
    return (
        <div className="home content">
            <HomeHeader />
            <div className="section info">
                <h1>Kdy?</h1>
                <div>
                    14. září 2024 <br/> <br/>
                    obřad v 11:00 <br/>
                    (ale klidně přijeďte dřív)
                </div>
                <br/>
                <div>
                    Pokud jste to ještě neudělali, prosím <a href={"/register"}>registrujte se</a>.
                </div>
                <h1>Kde?</h1>
                <div>
                    V Zemi Keltů v Nasavrkách.
                </div>
                <div>
                    <br/>
                    <iframe
                        title={'maps'}
                        className='seznamMap'
                        src="https://en.frame.mapy.cz/s/celubesojo"
                    ></iframe>
                </div>
                <br/>
                <br/>
                <div>
                <div style={{maxWidth:"300px", margin: "auto"}}>
                    Pokud máte nějaký dotaz, napište nám na libovolný kanál nebo nám zavolejte.
                </div>
                </div>
            </div>
        </div>
    );
}
